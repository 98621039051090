import { createTheme, ThemeProvider } from "@material-ui/core";
import * as anchor from "@project-serum/anchor";
import {
  useWallet
} from "@solana/wallet-adapter-react";
import { useMemo } from "react";
import "./App.css";
import Minter from "./Minter";

const theme = createTheme({
  palette: {
    type: "dark",
  },
  overrides: {
    MuiButton: {
      root: {
        '&:hover': {
          backgroundColor: 'white !important'
        }
      }
    }
  }
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const Home = () => {
  const wallet = useWallet();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  return (
    <div>
      <div className="content-wrapper">
        <header className="card" id="link1">
          <div style={{ padding: "0 24px 0 24px 0" }}>
            <h3 className="text-secondary-color">Welcome To</h3>
            <h1 className="pb-3">Clowns vs. Heroes</h1>
            <p className="text-secondary-color">
              The first Solana project where you can name your NFT. Give your clown or hero a specific name to be remembered in history!
            </p>
          </div>
          <div>
            <ThemeProvider theme={theme}>
              <Minter
                candyMachineId={candyMachineId}
                connection={connection}
                startDate={startDateSeed}
                txTimeout={txTimeout}
                rpcHost={rpcHost}
              />
            </ThemeProvider>
          </div>
        </header>

        <div id="link2" className="container scrim scrim-text" style={{ padding: "10px 24px 10px 24px" }}>
          Our team of 4 assembled to bring the first project of its kind to Solana. We want to make a living project
          on Solana that can exist far beyond our own lives. 2699 visionary members will join our ranks in building one of the most unique assets on chain.
          <div style={{ padding: "12px" }}></div>
          <p><em>Make history and own the first living Solana NFT!</em></p>
        </div>

        <div id="link3" className="container card">
          <h2 className="pb-3">Will you choose to be a hero?</h2>
          <h2>Or live your life as a clown?</h2>
        </div>

        <div id="link4" className="container faq scrim scrim-text">
          <h1 style={{ padding: "0 0 24px 0" }}>About</h1>
          <div>
            <h4>How Many Whitelists Spots Are There?</h4>
            <p>
              We have saved up to 500 whitelist spots for existing NFT communities on Solana.
            </p>

            <hr />
          </div>

          <div>
            <h4>Who Are The Members Of The Team?</h4>
            <div>
              <h4>tendiesman <span>- Founder and Developer</span></h4>
              <h4>Solana_King <span>- Marketing and Outreach</span></h4>
              <h4>Nito323 <span>- Community Manager and Head of Memes</span></h4>
              <h4>BowTiedCrocodile <span>- Developer</span></h4>
            </div>
            <hr />
          </div>

          <div>
            <h4>How Does The Name Change Work?</h4>
            <p>
              For the first week name changes will be <i>half off</i> to encourage minters to dedicate a new identity to their purchase!
              See the Name Change at the top menu for more.
            </p>
            <hr />
          </div>

          <div>
            <h4>Where Can I Learn More?</h4>
            <p>
              Join our <a href="https://discord.gg/tMPhRKMZuN">Discord</a> Community!
            </p>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
