import React, { useEffect } from 'react';

export interface ImageProps {
    src: any,
    name: string
}

const AsyncImage = (props: ImageProps) => {
    const [loadedSrc, setLoadedSrc] = React.useState(null);
    
    useEffect(() => {
        setLoadedSrc(null);
        if (props.src) {
            const handleLoad = () => {
                setLoadedSrc(props.src);
            };
            const image = new Image();
            image.addEventListener('load', handleLoad);
            image.src = props.src;
            return () => {
                image.removeEventListener('load', handleLoad);
            };
        }
    }, [props.src]);

    if (loadedSrc === props.src) {
        return ( <>
            <img src={props.src} 
            alt={props.name} 
            width={window.innerWidth > 768 ? "400px" : "250px"} 
            height={window.innerWidth > 768 ? "400px" : "250px"}/>
            <div></div>
            <h3 style={{textAlign : "end"}}>{props.name}</h3>
        </>
        );
    } else {
        <div></div>
    }
    return null;
};

export default AsyncImage;