/* eslint-disable no-script-url */
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { WalletDialogProvider, WalletMultiButton } from "@solana/wallet-adapter-material-ui";
import { ConnectionProvider, WalletProvider } from "@solana/wallet-adapter-react";
import { getMathWallet, getPhantomWallet, getSolflareWallet, getSolletWallet } from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Gallery from "./Gallery";
import Home from "./Home";

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);
  const wallets = useMemo(
    () => [getPhantomWallet(), getSolflareWallet(), getSolletWallet(), getMathWallet()],
    []
  );

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    const walletButton = document.getElementById("wallet-button")!;
    const body = document.getElementById("body")!;
    menu.classList.toggle("open-menu");
    walletButton.classList.toggle("mobile-background");
    body.classList.toggle("overflow-hidden");
  }

  const openDiscord = () => {
    window.open("https://discord.gg/tMPhRKMZuN");
  }
  const openTwitter = () => {
    window.open("https://twitter.com/clownsvsheroes");
  }

  const routes = {
    home: "/",
    gallery: "/gallery",
  }

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletDialogProvider>
          <div>
            <div id="wallet-button" className="wallet-banner">
              <WalletMultiButton />
            </div>
            <div id="mobileNavContainer" className="mobile-nav">
              <div className="mobile-nav-close-button" >
                <img src="/icons/close.svg" alt="" onClick={toggleMenu} />
              </div>
              <ul>
                <li>
                  <img className="mobile-nav-logo" src="/img/clowns.gif" alt="" />
                </li>
                <li>
                  <a href={routes.home} onClick={toggleMenu}>
                    Home
                  </a>
                </li>
                <li>
                  <a href={routes.gallery} onClick={toggleMenu}>
                    Gallery
                  </a>
                </li>
                <li>
                  <a href="https://magiceden.io/" target="_blank" rel="noreferrer" onClick={toggleMenu}>
                    Marketplace
                  </a>
                </li>
                <li>
                  <div className="social-icons">
                    <img className="nav-social" src="/icons/twitter.svg" onClick={openTwitter} alt="" />
                    <img className="nav-social" src="/icons/discord.svg" onClick={openDiscord} alt="" />
                  </div>
                </li>
              </ul>
            </div>
            <div className="mobile-menu-button" onClick={toggleMenu}>
              <img src="/icons/menu.svg" alt="" />
            </div>
            <nav>
              <div className="nav-container">
                <img className="nav-logo" src="/img/clowns.gif" alt="" />
                <a className="hide-800" href={routes.home}>
                  Home
                </a>
                <a style={{ textAlign: 'center' }} className="hide-800" href={routes.gallery}>
                  Gallery
                </a>
                <a className="hide-800" target="_blank" rel="noreferrer" href="https://magiceden.io/">
                  Marketplace
                </a>
                <div className="social-icons hide-800">
                  <img className="nav-social" src="/icons/twitter.svg" onClick={openTwitter} alt="" />
                  <img className="nav-social" src="/icons/discord.svg" onClick={openDiscord} alt="" />
                </div>
              </div>
            </nav>
            <Routes>
              <Route path={routes.home} element={<Home />} />
              <Route path={routes.gallery} element={<Gallery />} />
            </Routes>
          </div>
        </WalletDialogProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
