import { Button, CircularProgress, TextField } from "@material-ui/core";
import { programs } from "@metaplex/js";
import * as anchor from "@project-serum/anchor";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { useMemo, useState } from "react";
import "./App.css";
import AsyncImage from "./AsyncImage";

const {
  metadata: { Metadata },
} = programs;

const Gallery = (props: any) => {
  const [nftId, setNftId] = useState<string>("");
  const [nftMetadata, setMetadata] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const wallet = useWallet();
  const { connection } = useConnection();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const anchorWallet = useMemo(() => {
    if (
      !wallet ||
      !wallet.publicKey ||
      !wallet.signAllTransactions ||
      !wallet.signTransaction
    ) {
      return;
    }

    return {
      publicKey: wallet.publicKey,
      signAllTransactions: wallet.signAllTransactions,
      signTransaction: wallet.signTransaction,
    } as anchor.Wallet;
  }, [wallet]);

  const handleQuery = async (nftId: string) => {
    if (!nftId) {
      return;
    }
    setLoading(true);
    const tokenMetaData = await Metadata.findByMint(connection, new PublicKey(nftId));
    const response = await fetch(tokenMetaData.data.data.uri);
    const arweaveMetaData = await response.json();
    arweaveMetaData.name = tokenMetaData.data.data.name;
    setMetadata(arweaveMetaData);
    setLoading(false);
  };

  const handleInput = (event: any) => {
    setNftId(event!.target?.value);
  }

  return (
    <div>
      <div className="content-wrapper">
        <header className="card" id="link1" style={{ minHeight: "470px" }}>
          <div style={{ padding: "0 24px 0 24px 0" }}>
            <h3 style={{ paddingBottom: "10px" }}>Enter CvH Token Address:</h3>
            <TextField id="outlined-basic" label="ID" variant="outlined" required onChange={handleInput} value={nftId} />
            <div style={{ marginTop: "20px" }}>
              <Button variant="contained" color="primary" onClick={() => handleQuery(nftId)}>Enter</Button>
            </div>
          </div>
          {nftMetadata ? (<div>
            <AsyncImage {...{ src: nftMetadata.image, name: nftMetadata.name }} /></div>)
            : isLoading &&
            <CircularProgress />
          }
        </header>
        {nftMetadata && <div id="link2" className="container scrim scrim-text" style={{ padding: "10px 24px 10px 24px" }}>
          <h2>Traits</h2>
          <div>
            <ul>
              {
                nftMetadata.attributes.map((item: any) =>
                  <li key={item.trait_type}>{item.trait_type} - {item.value}</li>
                )
              }
            </ul>
          </div>
        </div>
        }
      </div>
    </div>
  );
}

export default Gallery;
